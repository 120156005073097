<template lang="pug">
settings-page(title="Уведомления")
  settings-panel(v-loading="isFetching")
    .notification-page
      .row
        email-block(
          :user="me"
          ref="refEmail"
          v-model="notifications.emailAccount.email"
          @validate:error="errorChecker"
          @validate:correct="correctChecker")
        email-notification-block(
          v-if="enableEmailNotifications"
          v-model:enableNotifications="notifications.emailAccount.enableNotifications"
          v-model:autoCreateTemplateNotification="notifications.emailAccount.autoCreateTemplateNotification")
      sms-notification-block(
        v-if="enableSmsNotifications"
        full-info
        v-model="notifications.phoneAccount.settings")
      .action-wrapper
        .error(v-if="error") {{ error }}
        .warning(v-else-if="hasChanges") У вас есть несохраненные изменения, не забудьте сохранить их
        .actions
          ui-button(:loader="isSaving" @click="validate") Сохранить изменения
          ui-button(v-if="enableSmsNotifications || enableEmailNotifications" type="secondary" @click="toHistory") История уведомлений
      .sms_info(v-if="enableSmsNotifications")
        h2 Уведомления направляются по часовому поясу получателя в следующем порядке:
        h3 Подача заявки:
        | Для тендеров со статусом «Подача заявки»:

        ol
          li В выбранное время предыдущего рабочего дня предшествующего дню срока истечения подачи заявки;
          li За N ч. до истечения срока подачи заявки.

        i Примечание: Если уведомление «за N ч.» имеет время с 23:00 до 8:00 по часовому поясу получателя, то оно не отправляется.

        h3 Торги:
        | Для тендеров со статусом «Торги»:

        ol
          li За N ч. до начала аукциона;
          li За N мин. до начала аукциона.

        i Примечание: Если время уведомления попадает в интервал с 23:00 до 8:00 по часовому поясу получателя, то уведомление отправляется в 17:30 предыдущего рабочего дня

        h3 Контракт:
        | Для тендеров со статусом «Контракт на подписи участника»:

        ol
          li В выбранное время предыдущего рабочего перед днем истечения подписания контракта (плановая дата, устанавливаемая вручную);
          li В выбранное время плановой даты истечения подписания контракта.
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useForm } from "@/use/other/useForm";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useNotificationsSettings } from "@/views/settings/notifications/useNotificationsSettings";
import { useRouter } from "vue-router";
import { Tabs } from "@/router/tabs";
import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import EmailBlock from "@/components/pages/settings/notificationsPage/EmailBlock.vue";
import EmailNotificationBlock from "@/components/pages/settings/notificationsPage/EmailNotificationBlock.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import SmsNotificationBlock from "@/components/pages/settings/notificationsPage/SmsNotificationBlock.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "NotificationPage",
  components: {
    UiButton,
    SettingsPage,
    SettingsPanel,
    EmailBlock,
    SmsNotificationBlock,
    EmailNotificationBlock,
  },
  setup() {

    const refEmail = ref();
    const router = useRouter();

    const { me } = useUserAccess();
    const {
      notifications,
      isSaving,
      isFetching,
      error,
      hasChanges,
      getNotifications,
      updateNotifications,
    } = useNotificationsSettings();

    const { enableSmsNotifications, enableEmailNotifications } = useUserAccess();
    const { errorChecker, correctChecker, validateForm } = useForm(updateNotifications);

    getNotifications();

    function toHistory() {
      router.push({ name: Tabs.NotificationHistory });
    }

    function validate() {
      refEmail.value.validate()
      validateForm()
    }

    return {
      me,
      error,
      hasChanges,
      isSaving,
      isFetching,
      refEmail,
      notifications,
      enableSmsNotifications,
      enableEmailNotifications,
      toHistory,
      validate,
      errorChecker,
      correctChecker,
    }
  }
})
</script>

<style scoped lang="scss">
.notification-page {
  display: flex;
  flex-flow: column;
  gap: 40px;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .warning {
    font-size: 13px;
    font-weight: bold;
    color: var(--main-warning-color);
  }

  .error {
    font-size: 13px;
    color: var(--main-red-color);
  }

  .action-wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  .actions {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .sms_info {
    font-size: 14px;
    line-height: 18px;

    h2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
